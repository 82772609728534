@font-face {
  font-family: 'mark-ot';
  src:
    url('../public/fonts/MarkOT.woff'),
    url('../public/fonts/MarkOT.ttf') format('truetype');
}

@font-face {
  font-family: 'mark-ot-medium';
  src:
    url('../public/fonts/MarkOT-Medium.woff'),
    url('../public/fonts/MarkOT-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'mark-ot-bold';
  src:
    url('../public/fonts/MarkOT-Bold.woff'),
    url('../public/fonts/MarkOT-Bold.ttf') format('truetype');
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fill {
  font-variation-settings: 'FILL' 1;
}
